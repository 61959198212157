import { React, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { Footer, Navbar, ScrollToTop } from "../components";
import Aos from "aos";
import { ToastContainer } from "react-toastify";

function RootLayout() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <header className={`${pathname !== "/" ? "h-[70vh]" : "h-[100vh]"}`}>
        <Navbar />
        <section className="h-full relative">
          {pathname === "/" ? (
            <div className="text-white min-w-[250px] absolute z-[1] md:translate-x-0 md:translate-y-0 text-center md:top-[45%] md:left-[7%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
              <h1 className="text-[clamp(1.6rem,3.5vw,5rem)] whitespace-nowrap font-[Aquarium] text-center md:text-left leading-tight relative">
                Welcome to
                <br />
                <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#cca000] to-[#f5cc00]">
                  Dr. Hamdy Shehab
                </span>
                <br />
                engineering consultant
              </h1>
            </div>
          ) : (
            <div className="text-white min-w-[250px] absolute z-[1] text-center md:top-[45%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
              <h2 className="text-[clamp(2rem,3vw,4rem)] font-[Aquarium] text-center leading-tight relative">
                {pathname.slice(1) === "about" ||
                pathname.slice(1) === "contact"
                  ? pathname.slice(1) + " us"
                  : hash
                  ? pathname.slice(1, pathname.lastIndexOf("/"))
                  : pathname
                      .slice(pathname.lastIndexOf("/") + 1)
                      .split("-")
                      .join(" ")}
              </h2>
            </div>
          )}
          <Slider
            className="w-full h-full overflow-hidden"
            fade={true}
            infinite={true}
            slidesToShow={1}
            autoplay={true}
            autoplaySpeed={2000}
            speed={1500}
            swipe={false}
            swipeToSlide={false}
            arrows={false}
          >
            <img
              src="/images/landing-1.webp"
              alt="landing"
              className={`${
                pathname !== "/" ? "h-[70vh]" : "h-[100vh]"
              } brightness-75 w-full object-cover`}
              loading="lazy"
            />
            <img
              src="/images/landing-2.webp"
              alt="landing"
              className={`${
                pathname !== "/" ? "h-[70vh]" : "h-[100vh]"
              } brightness-75 w-full object-cover`}
              loading="lazy"
            />
            <img
              src="/images/landing-3.webp"
              alt="landing"
              className={`${
                pathname !== "/" ? "h-[70vh]" : "h-[100vh]"
              } brightness-75 w-full object-cover`}
              loading="lazy"
            />
          </Slider>
        </section>
      </header>
      <Outlet />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default RootLayout;

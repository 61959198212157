/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PROJECTS } from "../constant/Projects";
import { SlCalender } from "react-icons/sl";
import { MdOutlineHandshake } from "react-icons/md";
import Slider from "react-slick";

function ProjectDetails() {
  const [project, setProject] = useState({});
  const [isFeatured, setIsFeatured] = useState(false);
  const { category, title } = useParams();

  useEffect(() => {
    let tempProjects = PROJECTS.find((project) => project.url === category);
    setIsFeatured(tempProjects.featured);
    setProject(
      tempProjects.projects.find(
        (project) =>
          project.url.toLowerCase().trim() === title.toLowerCase().trim()
      )
    );
  }, [category, title]);

  if (!project) return null;

  return (
    <section className="py-20">
      <div className="flex items-center mx-auto gap-10 max-[1200px]:flex-col">
        {typeof project.img === "object" ? (
          <div className="max-[1200px]:w-[600px] max-[800px]:w-full w-1/3">
            <Slider
              slidesToScroll={1}
              slidesToShow={1}
              speed={500}
              infinite={true}
              arrows={false}
              autoplay={true}
              autoplaySpeed={3000}
            >
              {project.img.map((img, i) => (
                <div key={i} data-aos="slide-right" className="w-[600px]">
                  <img
                    src={img}
                    alt={project.title}
                    className="w-full object-cover"
                  />
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div data-aos="slide-right" className="w-[600px]">
            <img
              src={project.img}
              alt={project.title}
              className="w-full object-cover"
            />
          </div>
        )}
        <div className="flex flex-col xl:w-1/2 max-[1200px]:px-10">
          <h2 className="md:text-2xl text-xl font-bold max-[1200px]:text-center">
            {project.title}
          </h2>
          <ul className="my-2 flex flex-col gap-5 p-2">
            {project?.description && (
              <li className="flex items-center gap-4">
                {!isFeatured ? (
                  <p
                    className="text-gray-500 text-[15px] md:text-[18px] text-left"
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                ) : (
                  <div>
                    <p className="text-black font-bold text-[18px] text-left">
                      {project.description?.info}
                    </p>
                    <ul className="flex gap-2 flex-col text-gray-500 font-normal mt-2 text-left">
                      {project.description?.subInfo.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                    <a
                      href="https://docs.google.com/presentation/d/1hs6wffsvBEN4ECYIFs9z9Sdi9J3CfAIY/edit#slide=id.p4"
                      target="_blank"
                      rel="noreferrer"
                      className="text-center font-semibold hover:bg-black hover:text-white uppercase mx-auto block rounded-lg border-2 border-black w-32 py-2 mt-6 transition-all"
                    >
                      Read more
                    </a>
                  </div>
                )}
              </li>
            )}
            {project?.owner && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">Owner:</span>{" "}
                  {project?.owner}
                </p>
              </li>
            )}
            {project?.main_contractor && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">Main Contractor:</span>{" "}
                  {project?.main_contractor}
                </p>
              </li>
            )}
            {project?.executive_contractor && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">
                    Executive Contractor:
                  </span>{" "}
                  {project?.executive_contractor}
                </p>
              </li>
            )}
            {project?.partner && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">Partner:</span>{" "}
                  {project?.partner}
                </p>
              </li>
            )}
            {project?.year && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">Year:</span>{" "}
                  {project.year}
                </p>
              </li>
            )}
            {project?.location && (
              <li className="flex items-center gap-4">
                <p className="text-gray-500 text-left text-[15px] md:text-[18px]">
                  <span className="font-bold text-black">Location: </span>{" "}
                  {project.location}
                </p>
              </li>
            )}
            {project?.key_components && (
              <>
                <h2 className="md:text-2xl text-xl max-[1200px]:text-center font-bold">
                  Key Components:
                </h2>
                <ul className="flex flex-col gap-4">
                  {project?.key_components.map((item, index) => (
                    <li key={index} className="text-gray-500 text-left">
                      <span className="font-bold text-black">
                        {item.title}:{" "}
                      </span>
                      {item.description}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {project?.structure && (
              <>
                <h2 className="md:text-2xl text-xl max-[1200px]:text-center font-bold">
                  Structure:
                </h2>
                <ul className="flex flex-col gap-2 list-disc ml-5">
                  {project?.structure.map((item, index) => (
                    <li key={index} className="text-gray-500 text-left">
                      {item}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {project?.key_highlights && (
              <>
                <h2 className="md:text-2xl text-xl max-[1200px]:text-center font-bold">
                  Key Highlights:
                </h2>
                <ul className="flex flex-col gap-2 list-disc ml-5">
                  {project?.key_highlights.map((item, index) => (
                    <li key={index} className="text-gray-500 text-left">
                      {item}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ProjectDetails;

import { React } from "react";

function Contact() {
  return (
    <section className="bg-center bg-cover bg-no-repeat h-full py-10 w-full relative">
      <img
        src="/images/contactus.webp"
        alt="contactus"
        className="h-full object-cover absolute top-0 left-0 brightness-50"
      />
      <div className="px-5 py-10 max-w-[900px] m-auto flex flex-col gap-7 items-center text-center text-white relative z-10">
        <h2 className="font-[700] text-[clamp(1rem,1.6vw,3rem)] capitalize">
          feel free to contact us for any inquiries or questions you may have
          and we will get back to you as soon as possible
        </h2>
        <a
          href="/contact"
          className="mx-auto block text-center w-40 border-2 p-4 border-white rounded text-white font-semibold hover:border-[black] hover:bg-[black] hover:text-white transition uppercase"
        >
          contact us
        </a>
      </div>
    </section>
  );
}

export default Contact;

import { Autoplay, Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CLIENTS } from "../constant/Clients";

function Clients() {
  return (
    <section className="py-16">
      <h2 className="text-center uppercase font-bold text-[clamp(2rem,3.5vw,4rem)] mb-[20px] font-[Aquarium]">
        Clients
      </h2>
      <div className="container mx-auto">
        <div>
          <Swiper
            style={{
              "--swiper-pagination-color": "black",
              "--swiper-wraper-align-items": "center",
            }}
            breakpoints={{
              0: { zoom: true, slidesPerView: 1 },
              600: { zoom: false, slidesPerView: 3 },
            }}
            spaceBetween={20}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            autoplay={{ delay: 2500 }}
            loop={true}
            modules={[Pagination, Zoom, Autoplay]}
            allowTouchMove={false}
            className="mySwiper py-[20px]"
          >
            {CLIENTS.map((client, index) => (
              <SwiperSlide key={`CL-${index}`}>
                <div className="swiper-zoom-container flex justify-center">
                  <img
                    src={client.img}
                    alt={client.title}
                    className="w-[150px]"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Clients;

import { React } from "react";
import { PROJECTS } from "../constant/Projects";

function ProjectsPage() {
  return (
    <section className="py-10 px-2">
      <div className="container max-w-[1250px] grid grid-cols-[repeat(auto-fill,minmax(400px,1fr))] max-[450px]:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-x-[40px] gap-y-[20px] m-auto">
        {PROJECTS.map((project, index) => (
          <a
            key={index}
            href={`projects/${project.url}`}
            className={`${
              !index
                ? "md:col-span-2 lg:h-[400px]"
                : index === 1 || index === PROJECTS.length - 1
                ? "md:h-[300px]"
                : "md:h-[600px] md:row-span-2"
            } group h-[500px] text-center relative overflow-hidden cursor-pointer shadow-[5px_5px_5px_5px_#b4b4b4]`}
            data-aos="fade-up"
            data-aos-delay={index * 100}
          >
            <img
              src={project.img}
              alt={project.category}
              className="h-full w-full object-cover max-md:brightness-50 group-hover:brightness-50 transition-all"
              loading="lazy"
            />
            <h2 className="absolute -bottom-[50%] text-sm left-[50%] max-md:underline -translate-x-[50%] md:group-hover:bottom-[50%] md:group-hover:left-[50%] md:group-hover:-translate-x-[50%] md:group-hover:translate-y-[50%] md:group-hover:scale-150 max-md:bottom-[50%] max-md:left-[50%] max-md:-translate-x-[50%] max-md:translate-y-[50%] max-md:scale-150  transition-all  uppercase text-white duration-[.5s] font-semibold">
              {project.category}
            </h2>
          </a>
        ))}
      </div>
    </section>
  );
}

export default ProjectsPage;

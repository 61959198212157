import { React } from "react";
import { SERVICES } from "../constant/Services";

function Services() {
  return (
    <section>
      <h2 className="text-center uppercase font-bold text-[clamp(2rem,3.5vw,4rem)] mb-[20px] font-[Aquarium]">
        services
      </h2>
      <div>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] ">
          {SERVICES.slice(0, 4).map((service, index) => (
            <a
              key={`SR-${index}`}
              href={`/services/#${service.id}`}
              className={`relative group aspect-[1/1.5]`}
              data-aos="zoom-in"
              data-aos-delay={index * 100}
            >
              <img
                src={service.img}
                alt={service.title}
                className="group-hover:brightness-50 object-cover max-md:brightness-50 brightness-[.7] transition-all duration-[.7s]"
                loading="lazy"
              />
              <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-center w-full flex flex-col gap-3 items-center  transition-all duration-[.7s]">
                <h2 className="text-[clamp(1.4rem,1.8vw,4rem)]  font-[Aquarium]  text-center text-white transition-all leading-9 duration-[.7s] relative group-hover:scale-105 max-md:scale-105 before:absolute before:content-[''] group-hover:before:w-full max-md:before:w-full  before:w-0 before:left-[50%] before:-translate-x-[50%] before:h-[2px] before:-bottom-[5px] before:bg-white before:transition-all before:duration-[.7s]">
                  {service.title}
                </h2>
              </div>
            </a>
          ))}
        </div>
        <a
          className="text-center font-semibold capitalize mx-auto mt-10 w-32 py-2 hover:invert bg-white block rounded-lg border-2 border-black transition-all"
          type="button"
          href="/services"
        >
          View All
        </a>
      </div>
    </section>
  );
}

export default Services;

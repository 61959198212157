import { useState } from "react";

export default function MissionAndVission({ image, title, content, index }) {
  const [isHidden, setIsHidden] = useState(true);
  function handleShowMore() {
    setIsHidden((prev) => !prev);
  }
  return (
    <div
      className={`flex justify-evenly items-center ${
        index ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <div
        className="w-1/2 max-lg:hidden h-[600px]"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <img src={image} alt={title} className="h-full w-full object-cover" />
      </div>
      <div
        className="flex flex-col max-lg:text-center p-5 lg:w-1/2"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <h2 className="text-[clamp(1.7rem,3.2vw,5rem)]  font-[Aquarium] font-bold">
          {title}
        </h2>
        <p className="leading-8 text-gray-500 ">
          {content.slice(0, 165)}
          <span className={`${isHidden ? "md:hidden" : "hidden"}`}>...</span>
          <button
            className={`text-black font-bold ${
              isHidden ? "md:hidden" : "hidden"
            } hover:underline`}
            onClick={handleShowMore}
          >
            Read more
          </button>
          <span className={`${isHidden ? "max-md:hidden" : ""}`}>
            {content.slice(165, content.length)}
          </span>
          <button
            className={`text-black font-bold md:hidden ${
              isHidden ? "max-md:hidden" : ""
            } hover:underline`}
            onClick={handleShowMore}
          >
            Read Less
          </button>
        </p>
      </div>
    </div>
  );
}

export const SERVICES = [
  {
    title: "Studies & Master planning",
    id: "studies-&-master-planning",
    img: "/images/services/studingandmaster.webp",
    more: false,
    content:
      "Our consulting engineering office specializes in Studies & Master Planning services. We conduct thorough research, feasibility studies, and assessments to understand project objectives and challenges. Our experienced team develops comprehensive master plans, incorporating urban design and sustainable development principles. We collaborate closely with clients, stakeholders, and authorities, ensuring compliance with regulations and environmental standards. Our goal is to deliver high-quality, actionable plans that support successful project execution and drive sustainable growth. Contact us to explore how our services can unlock your project's full potential.",
  },
  {
    title: "Engineering Design",
    id: "engineering-design",
    img: "/images/services/engineeringdesign.webp",
    more: false,
    content:
      "Our consulting engineering office specializes in Engineering Design services, offering innovative and practical solutions for various projects. With a focus on functionality, efficiency, and safety, our experienced team collaborates closely with clients to develop customized designs that meet their specific needs. We integrate cutting-edge technologies, sustainability principles, and industry best practices into our designs, ensuring long-term viability and minimal environmental impact. From concept development to detailed design, we prioritize collaboration, communication, and delivering cost-effective solutions without compromising quality or safety. Contact us today to discuss your project requirements and experience our commitment to exceptional, reliable, and sustainable engineering design.",
  },
  {
    title: "Architectural Design",
    id: "architectural-design",
    img: "/images/services/architecturedesign.webp",
    more: false,
    content:
      "Ignite your architectural vision with our innovative Architectural Design services. Our talented team of architects and designers creates captivating and functional spaces that transcend expectations. We prioritize creativity, functionality, and sustainability, integrating cutting-edge technologies and eco-friendly materials. Collaboration is at the core of our process, tailoring designs to reflect your unique vision. Using state-of-the-art tools, we bring your dreams to life with meticulous attention to detail. From seamless coordination to flawless execution, our designs exceed expectations with beauty, practicality, and technical excellence. Contact us to embark on a transformative journey, where we create inspiring spaces that leave a lasting impact.",
  },
  {
    title: "Rehabilitation",
    id: "project-management",
    img: "/images/services/projectmanagment.webp",
    more: false,
    content:
      "Our expert consulting engineering team specializes in comprehensive building rehabilitation services, dedicated to restoring and revitalizing existing structures. We combine our extensive knowledge, innovative solutions, and meticulous attention to detail to breathe new life into aging or deteriorated buildings. Whether it's renovating historical landmarks, upgrading outdated facilities, or enhancing structural integrity, our tailored rehabilitation approach ensures seamless integration of modern advancements while preserving the unique character and architectural heritage. From thorough assessments and planning to efficient project management and quality execution, we are committed to delivering sustainable, cost-effective solutions that optimize functionality, safety, and aesthetic appeal. Trust us to transform your buildings into renewed assets, maximizing their longevity and value for generations to come.",
  },
  {
    title: "Construction Management & Supervision",
    id: "construction-management-&-supervision",
    img: "/images/services/ConstructionManagementSupervision.webp",
    more: false,
    content:
      "Mbark on a construction journey like no other with our exceptional Construction Management & Supervision services. We synchronize timelines, budgets, and resources, orchestrating a symphony of success. Our seasoned team ensures adherence to design specifications, safety protocols, and industry standards. Transparent communication and meticulous oversight are our hallmarks. Partner with us to build a legacy that stands the test of time. Contact us today for exceptional Construction Management & Supervision services that bring your vision to life.",
  },
  {
    title: "Soil Investigation",
    id: "soil-borings",
    img: "/images/services/Soil-Borings.webp",
    more: true,
    content:
      "Unveil the hidden secrets beneath the Earth's surface with our comprehensive soil boring services. Our team of experienced geotechnical engineers and technicians uses cutting-edge equipment to gather valuable data on soil composition, geotechnical properties, and groundwater conditions. We provide precise and detailed analysis to support site development, foundation design, and infrastructure projects. Collaborating closely with clients, we deliver clear reports and actionable recommendations. Contact us today to harness the power of our soil boring expertise and lay the foundation for success in your engineering projects.",
    info: [
      "Sohag Bridge, Located at Railway Cairo-Aswan, Sohag Government.",
      "Industrial Complex, owned by the Construction company in Egypt, located at Obour City, Cairo Government.",
      "Al Olaya, owned by Contracting Company of Egypt, is located at Obour City, Cairo Government.",
      "Residential Buildings, located at Different Governments of the Republic.",
      "Villas And Buildings, located at First and Fifth settlement, New Cairo, Cairo Government.",
      "Villas And Buildings, located at the 6th of October city, Giza government, and Obour City, Cairo Government.",
      "Dar Al-Salam Tunnel, located at Autostrad Rd, Cairo Government.",
      "Social Houses, located at El Sadat City, Monufia Government.",
      "Social Houses, located at Badr City, Cairo Government.",
      "Social Houses, located at10th of Ramadan City, Sharqia Government",
      "Huckstep Houses, located at Ismailia Road, Cairo Government.",
      "Social Houses, located at 15th May City, Helwan Government.",
      "Rock Tunnel, located at Autostrad Rd, Maadi, Cairo Government.",
      "Public Sewer, located at North Coast.",
    ],
  },
  {
    title: "Assessment And Evaluation",
    id: "assessment-and-evaluation",
    img: "/images/services/Assessment And Evaluation.webp",

    more: true,
    content:
      "Experience the power of informed decision-making with our comprehensive Assessment and Evaluation services. Our team of experts meticulously analyzes and evaluates every aspect of your engineering projects, providing valuable insights and guiding you toward optimal outcomes. From assessing feasibility and compliance to enhancing performance and sustainability, we ensure that your projects are on the right track. With transparent reporting and actionable recommendations, we empower you to make informed decisions and maximize the value of your investments. Partner with us to unlock the full potential of your projects through our Assessment and Evaluation services. Contact us today for a future of engineering excellence.",
    info: [
      "Evaluation of the assets of the Egyptian Manufactures Sale Company in 1994 for all its various branches at the level of the Republic, whether from buildings, lands, furniture, etc., and it was presented to the holding company for it so that it could be a basis when offering it to investors.",
      "A recent evaluation of thirty units of the branches and stores of the Egyptian Industries Company, each separately, was put up and sold at public auction.",
      "Evaluation of some of the assets of the luxury fashion houses company in order to offer and sell them publicly",
      "The evaluation of a number of the National Bank of Egypt, the most important of which were:",
      [
        "Evaluation of the Hurghada International Hospital project in Hurghada, owned by the International Medical Tourism Company.",
        "Evaluation of a number of the assets of Mr. Mohamed Junaidi GMC, a commercial center in Gamaet El Dowal Al Arabiya Street.",
        "Agricultural land in the green belt, 6th of October City.",
        "Land for buildings in the plateau of the pyramids.",
        "Residential Apartments at Mohandessin.",
        "Evaluation of Villa No. 14/3 in the village of Al-Tale'a, Cairo-Ismailia Road.",
        "A commercial center next to El Serag Mall, Nasr City.",
        "Hafez Farm, Cairo-Alexandria Desert Road.",
      ],
    ],
  },
];

import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SERVICES } from "../constant/Services";

function SingleService() {
  const { title } = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    setService(
      SERVICES.find(
        (service) =>
          service.id.toLowerCase().trim() === title.toLowerCase().trim()
      )
    );
  }, [title]);

  if (!service) return null;

  return (
    <div className="container py-16 mx-auto flex flex-col gap-20">
      <div className="flex flex-row items-center gap-10 p-5 overflow-hidden justify-center">
        <div
          className="w-[400px] h-[550px] max-lg:hidden"
          data-aos="fade-right"
        >
          <img
            src={service.img}
            alt={service.title}
            className="h-full object-cover"
          />
        </div>
        <div className="info flex-col flex gap-5 max-w-[800px]">
          <h2 className="font-bold text-2xl">{service.title}</h2>
          <ul className="flex flex-col gap-3">
            {service.info.map((info, index) => (
              <li key={index} className="flex gap-2 items-center">
                {typeof info == "string" ? (
                  <>
                    <span className="bg-gray-500 rounded text-center w-8 h-8 flex items-center p-2 justify-center font-normal text-white font-[Aquarium]">
                      {index < 9 ? `0${index + 1}` : index + 1}
                    </span>
                    {info}
                  </>
                ) : (
                  <ul className="col-span-2 ml-10 sm:ml-[90px] list-disc flex flex-col gap-5">
                    {info.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SingleService;

import { React } from "react";

function AboutUs() {
  return (
    <section className="py-10 lg:pb-20">
      <h2 className="text-center uppercase font-bold text-[clamp(2rem,3vw,4rem)] mb-5 font-[Aquarium]">
        who we are
      </h2>
      <div
        className="flex justify-evenly items-center container mx-auto gap-5 px-5"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <div className="max-lg:text-center lg:w-1/2 flex flex-col gap-5">
          <h2 className="text-[#cca000] font-bold capitalize text-[clamp(1.5rem,2vw,2rem)]">
            about our company
          </h2>
          <p className="text-gray-500 text-[clamp(.8rem,2vw,1.2rem)] lg:pr-24 leading-6">
            Welcome to Dr. Hamdy Shehab With our expert engineering
            consultation.we aim to benefit the whole of society, by being the
            safer, cleaner, and more efficient foundations with our project
            management skills, and our strong interpersonal.
          </p>
          <a
            className="text-center font-semibold capitalize max-lg:mx-auto w-32 py-2 hover:invert bg-white block rounded-lg border-2 border-black transition-all"
            href="/about"
          >
            View more
          </a>
        </div>
        <div className="flex justify-between w-1/2 max-lg:hidden">
          <div
            className="w-[48%] h-[500px] max-xl:h-[350px] shadow-[5px_5px_5px_5px_#b4b4b4] relative top-10"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <img
              src="/images/about/aboutus1.webp"
              alt="about us"
              className="h-full w-full object-cover"
            />
          </div>
          <div
            className="w-[48%] h-[500px] max-xl:h-[350px] shadow-[5px_5px_5px_5px_#b4b4b4]"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            <img
              src="/images/about/aboutus2.webp"
              alt="about us"
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
